import React from 'react'

function Design() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        
    </div>
  )
}

export default Design